import styled from "styled-components";
import { default as MuiPaper } from "@material-ui/core/Paper";

export const Paper = styled(MuiPaper)`
  background: white;
  height: 175px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
    width: 95%;
    height: auto;
  }
`;
