import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icons, Tool } from "../Types/tools.types";
import BackNav from "../../atoms/BackNav/BackNav.component";
import LayoutPage from "../../molecules/LayoutPage/Layout.page";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import ItemsCarousel from "react-items-carousel";
import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Container,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import * as Style from "./ToolDetail.styles";
import { magClient } from "../../../mag-client";

export const ToolDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [status, setStatus] = useState("idle");
  const [tool, setTool] = useState<null | Tool>(null);
  const [selectedImg, setSelectedImg] = useState<null | string>(null);
  const [openZoom, setOpenZoom] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleZoomClick = ({ target }) => {
    setSelectedImg(target.src);
    setOpenZoom(true);
  };
  useEffect(() => {
    setStatus("loading");
    magClient.instance
      .get(`${process.env.REACT_APP_API_BASE_URL}/tools/${id}`)
      .then((res) => res.data)
      .then((result) => {
        setStatus("success");
        setTool(result);

        return result;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setStatus("error");
      });
  }, [id]);
  if (!tool) return null;

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <LayoutPage>
      <Grid container justify="flex-start">
        <Grid item xs={12} md={10}>
          <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            onClose={() => setOpenZoom(false)}
            aria-labelledby="image viewer"
            open={openZoom}
          >
            <img src={selectedImg} alt="image zoom" />
          </Dialog>
          <Box my={2}>
            <BackNav />
          </Box>
          {status !== "success" ? null : (
            <Style.Paper elevation={3}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} md={4} lg={2}>
                  <ButtonBase>
                    <img
                      src={tool?.logo_icon_url}
                      alt=""
                      style={{ maxWidth: "90%" }}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box mx={4}>
                    <Typography variant="h4" component="h2">
                      <Box fontWeight="300" my={1}>
                        {tool?.tool_name}
                      </Box>
                    </Typography>
                    <Typography variant="subtitle1" component="h3">
                      <Box fontWeight="700" color="#3f762c">
                        {tool?.tool_category_name}
                      </Box>
                    </Typography>
                    {tool?.status?.map((Icon: Icons, index) => (
                      <Typography key={index} variant="subtitle1" component="p">
                        {`${Icon?.icon_id} ${Icon?.icon_description}`}
                      </Typography>
                    ))}
                    <Typography variant="h4" component="p">
                      {`${tool?.price}€ `}
                      <Typography variant="subtitle1" component="span">
                        {`${tool?.price_label}`}
                      </Typography>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {tool?.forms && (
                <Box my={4} display={"flex"}>
                  <List component="nav" aria-labelledby="nested-list-subheader">
                    <Box my={1}>
                      <ListItem
                        button
                        onClick={handleClick}
                        style={{ padding: "0px" }}
                      >
                        <Button
                          variant="outlined"
                          href={`/tools/${id}/forms/${tool?.forms[0]?.id}`}
                        >
                          {tool?.forms[0]?.name}
                        </Button>
                        {tool?.forms.length > 1 ? (
                          <>
                            <Box mx={2}>
                              <ListItemText primary="Other requests" />
                            </Box>
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </>
                        ) : null}
                      </ListItem>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <Box display={"flex"} flexDirection="column">
                          {tool?.forms.map((form, index) =>
                            index != 0 ? (
                              <Box my={1} key={index}>
                                <Button
                                  variant="outlined"
                                  href={`/tools/${id}/forms/${form?.id}`}
                                >
                                  {form?.name}
                                </Button>
                              </Box>
                            ) : null
                          )}
                        </Box>
                      </List>
                    </Collapse>
                  </List>
                </Box>
              )}
              <Box my={4}>
                <Typography variant="subtitle1" component="h4">
                  <Box fontWeight="700" mb={2}>
                    Screenshots
                  </Box>
                </Typography>
                <Container>
                  <ItemsCarousel
                    classes={{
                      wrapper:
                        "MuiGrid-grid-xs-12 MuiGrid-grid-sm-7 MuiGrid-grid-md-10",
                    }}
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    infiniteLoop={false}
                    gutter={12}
                    chevronWidth={60}
                    disableSwipe={false}
                    alwaysShowChevrons={false}
                    numberOfCards={2}
                    slidesToScroll={2}
                    outsideChevron={true}
                    showSlither={false}
                    rightChevron={<ArrowForwardIosRoundedIcon />}
                    leftChevron={<ArrowBackIosRoundedIcon />}
                  >
                    {tool?.screenshots.map((item, index) => (
                      <div key={index}>
                        <img
                          style={{
                            width: "100%",
                          }}
                          src={item}
                          alt=""
                          onClick={handleZoomClick}
                        />
                      </div>
                    ))}
                  </ItemsCarousel>
                </Container>
              </Box>
              <Style.MarkDownContainer my={4}>
                <Typography variant="subtitle1" component="h4">
                  <Box fontWeight="700" mb={2}>
                    Description
                  </Box>
                </Typography>
                <ReactMarkdown plugins={[gfm]} linkTarget="_blank">
                  {`${tool?.description_in_markdown}`}
                </ReactMarkdown>
              </Style.MarkDownContainer>
            </Style.Paper>
          )}
        </Grid>
      </Grid>
    </LayoutPage>
  );
};
export default ToolDetail;
