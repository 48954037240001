import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { TokenStorage } from "../../../token-storage";
import copy from "copy-to-clipboard";
import Alert from "@material-ui/lab/Alert";

const Jwt = () => {
  const myToken = TokenStorage.getToken();
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const handleClose = () => {
    setOpenConfirmation(false);
  };

  return (
    <Box
      style={{ height: "100vh", width: "100vw" }}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Snackbar
        open={openConfirmation}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          elevation={6}
        >
          Copied !
        </Alert>
      </Snackbar>
      <Box pt={5}>
        <h1 color="textSecondary">Bearer JWT token </h1>
      </Box>
      <Box mb={2}>
        <Typography color="textSecondary">
          ⚠ The jwt token should be place in the header of your request. It's
          used by the backend to know the role and the rights of a user. You
          should not share this token with anyone.
        </Typography>
      </Box>
      <Card style={{ width: "50%" }}>
        <CardContent style={{ overflow: "scroll" }}>
          <Typography color="textSecondary">{myToken}</Typography>
        </CardContent>
      </Card>
      <Box m={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ height: "45px", width: "150px" }}
          onClick={() => {
            copy(myToken);
            setOpenConfirmation(true);
          }}
        >
          Copy
        </Button>
      </Box>
    </Box>
  );
};

export default Jwt;
