import React, { useContext } from "react";
import { Tool } from "../../organismes/Types/tools.types";
import ToolItem from "../../atoms/ToolItem/ToolItem.component";
import { FiltersContext } from "../../organismes/Contexts/FiltersContext.component";
import { Box, Divider, Grid, Link, Typography } from "@material-ui/core";
import * as Styled from "./AllTools.styles";

export const AllTools: React.FC<{
  tools: Tool[];
  filteredTools: Tool[];
}> = ({ tools, filteredTools }) => {
  const { setFilteredTools } = useContext(FiltersContext);
  const getFilters = (tools) => {
    const filtersMap = new Map<string, number>();
    tools?.forEach((tool) => {
      filtersMap.set(tool?.tool_category_name, 1);
    });
    return [...filtersMap.keys()];
  };

  const handleFilter = (stringForFilter = null) => {
    if (!stringForFilter) {
      setFilteredTools(tools);
      return;
    }
    const filteredTool = tools?.filter(
      (tool) => tool.tool_category_name === stringForFilter
    );
    setFilteredTools(filteredTool);
  };

  const allFilter = getFilters(tools);

  return (
    <>
      <Typography variant="h5" component="h2">
        <Box fontWeight="fontWeightBold" my={1}>
          All tools
        </Box>
      </Typography>
      <Divider />
      <Box mt={1}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
          xs={12}
        >
          <Grid item>
            <Styled.Filter variant={"outlined"} onClick={() => handleFilter()}>
              All Tools
            </Styled.Filter>
          </Grid>
          {allFilter?.map((filter, index) => (
            <Grid key={index} item>
              <Styled.Filter
                variant={"outlined"}
                value={"focus"}
                onClick={() => handleFilter(`${filter}`)}
              >
                {filter}
              </Styled.Filter>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box my={3}>
        <Styled.Grid container alignItems="center" spacing={5}>
          {filteredTools
            .sort((a, b) => a.tool_name.localeCompare(b.tool_name))
            .map((tool) => (
              <Grid item xs={6} md={4} lg={3} key={tool.id}>
                <Link
                  href={`/tools/${tool.tool_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <ToolItem tool={tool} />
                </Link>
              </Grid>
            ))}
        </Styled.Grid>
      </Box>
    </>
  );
};
export default AllTools;
