import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LayoutPage from "../../molecules/LayoutPage/Layout.page";
import ReactMarkdown from "react-markdown";
import BackNav from "../../atoms/BackNav/BackNav.component";
import gfm from "remark-gfm";
import {
  Box,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core";
import * as Style from "./TipDetail.styles";
import { magClient } from "../../../mag-client";

export const TipDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [status, setStatus] = useState("idle");
  const [tip, setTip] = useState<null | any>(null);

  useEffect(() => {
    setStatus("loading");
    magClient.instance
      .get(`${process.env.REACT_APP_API_BASE_URL}/tips/${id}`)
      .then((res) => res.data)
      .then((result) => {
        setStatus(result);
        setTip(result);
        return result;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setStatus("error");
      });
  }, [id]);
  if (!tip) return null;
  return (
    <LayoutPage>
      <Grid container justify="flex-start">
        <Grid item xs={12} lg={12}>
          <Box my={2}>
            <BackNav />
          </Box>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={8}>
              <Paper elevation={3}>
                <Box my={4} p={6}>
                  <Typography variant="h4" gutterBottom>
                    <Box mb={1}>{tip?.title}</Box>
                  </Typography>
                  <Style.MarkDownContainer>
                    <ReactMarkdown
                      plugins={[gfm]}
                    >{`${tip?.description}`}</ReactMarkdown>
                  </Style.MarkDownContainer>
                </Box>
              </Paper>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={4}>
                <Box my={4}>
                  <Grid container justify="flex-start" spacing={2}>
                    {tip?.related_tools?.map((relatedTool, index) => (
                      <Grid item xs={12} key={index}>
                        <Paper elevation={3}>
                          <Box mx={2} px={2} py={2}>
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Hidden mdDown>
                                <Grid item xs={3}>
                                  <img
                                    src={relatedTool.tool_icon_url}
                                    alt={relatedTool.tool_id}
                                    style={{ width: "100%" }}
                                  />
                                </Grid>
                              </Hidden>
                              <Grid item xs={9}>
                                <Typography variant="h5" component="h2">
                                  <Box fontWeight="fontWeightBold" my={1}>
                                    {relatedTool.tool_name}
                                  </Box>
                                </Typography>
                                <Typography variant="subtitle1" component="h2">
                                  {relatedTool.tool_short_description}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </LayoutPage>
  );
};
export default TipDetail;
