import React from "react";
import { Divider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import SpotlightSkeleton from "../../atoms/Spotlight/SpotlightSkeleton.component";

const AllSpotlightsSkeleton = () => {
  return (
    <>
      <Typography variant="h5" component="h2">
        <Box fontWeight="fontWeightBold" my={1}>
          <Skeleton animation="wave" variant="text" />
        </Box>
      </Typography>
      <Divider />
      <Box marginY={2}>
        <Grid container spacing={5}>
          {Array(3)
            .fill(null)
            .map((item, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <SpotlightSkeleton />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default AllSpotlightsSkeleton;
