import styled, { css } from "styled-components";
import { default as MuiAppBar } from "@material-ui/core/AppBar";
import { default as MuiToolbar } from "@material-ui/core/Toolbar";
import { default as MuiIconButton } from "@material-ui/core/IconButton";
import { default as MuiMenuIcon } from "@material-ui/icons/Menu";
import {
  Button as MuiButton,
  Typography as MuiTypography,
} from "@material-ui/core";

export const AppBar = styled(MuiAppBar)`
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
  background: white;
  padding: 5px 0;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%);
`;

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(0, 1)};
  justify-content: space-between;
  ${(props) =>
    props.theme.mixins.toolbar &&
    css`
      ${{ ...props.theme.mixins.toolbar }}
    `}
`;
export const IconButton = styled(MuiIconButton)`
  margin-right: ${(props) => props.theme.spacing(2) + "px"};
  ${(props) => props.theme.breakpoints.up("sm")} {
    && {
      display: none;
    }
  }
`;
export const MenuIcon = styled(MuiMenuIcon)``;
export const Typography = styled(MuiTypography)``;
export const Button = styled(MuiButton)``;
