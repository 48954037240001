import styled, { css } from "styled-components";
import { default as MuiSwipeableDrawer } from "@material-ui/core/SwipeableDrawer/SwipeableDrawer";
import { default as MuiDrawer } from "@material-ui/core/Drawer/Drawer";
import { default as MuiListItem } from "@material-ui/core/ListItem/ListItem";

export const drawerWidth = "240px";

export const NavContainer = styled.nav`
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    width: 240px;
    flex-shrink: 0;
  }
  a:hover {
    text-decoration: none;
  }
`;
export const SwappableDrawer = styled(MuiSwipeableDrawer)`
  .MuiDrawer-paper {
    width: ${drawerWidth};
  }
`;
export const Drawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    width: ${drawerWidth};
  }
`;

export const ListItem = styled(MuiListItem)`
  &.MuiListItem-button {
    :hover {
      background: var(--color-background);
      .MuiListItemText-primary {
        font-weight: 600;
      }
    }
  }
`;
export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(0, 1)};
  justify-content: flex-end;
  ${(props) =>
    props.theme.mixins.toolbar &&
    css`
      ${{ ...props.theme.mixins.toolbar }}
    `}
`;
