import { ITipsRepository } from "./i-tips-repository";
import { Tips } from "../components/organismes/Types/Tips.Types";
import axios from "axios";
import { magClient } from "../mag-client";

class TipsRepository implements ITipsRepository {
  getAllTips(): Promise<Tips[]> {
    return magClient.instance
      .get(`${process.env.REACT_APP_API_BASE_URL}/tips`)
      .then((res) => res.data)
      .then((result) => {
        const allTips = result as Tips[];
        return allTips;
      });
  }

  getTips(tips_id: string): Promise<Tips> {
    return Promise.resolve(undefined);
  }
}

export const tipsRepo = new TipsRepository();
