import styled from "styled-components";
import { default as MuiPaper } from "@material-ui/core/Paper";
import { default as MuiAvatar } from "@material-ui/core/Avatar";
import { default as MuiBox } from "@material-ui/core/Box";

export const Paper = styled(MuiPaper)`
  ${(props: any) => props.theme.breakpoints.up("lg")} {
    padding: 50px;
    margin: 50px;
  }
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    margin: 50px;
  }
  ${(props: any) => props.theme.breakpoints.up("xs")} {
    padding: 50px;
  }
`;

export const Avatar = styled(MuiAvatar)`
  max-width: 220px;
  max-height: 220px;
  width: 100%;
  height: auto;
  border-radius: 40px;
  background: #454545;
`;

export const ScreenShotImg = styled.img`
  width: 100%;
`;

export const MarkDownContainer = styled(MuiBox)`
  img {
    max-width: 100%;
  }
`;
