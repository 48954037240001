import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import LayoutPage from "../../molecules/LayoutPage/Layout.page";
import BackNav from "../../atoms/BackNav/BackNav.component";
import FormInput from "../../atoms/FormInput/FormInput.component";
import FormEmailInput from "../../atoms/FormInput/FormEmailInput.component";
import FormTextArea from "../../atoms/FormTextArea/FormTextArea.component";
import FormRadioButton from "../../atoms/FormRadioButton/FormRadioButton.component";
import FormCheckboxes from "../../atoms/FormCheckboxes/FormCheckboxes.component";
import FormCheckbox from "../../atoms/FormCheckboxes/FormCheckBox.components";
import FormSelect from "../../atoms/FormSelect/FormSelect.component";
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";
import Alert from "@material-ui/lab/Alert";
import {
  extractFormJsonSchema,
  extractUiSchema,
} from "../../../helpers/formHelpers";
import {
  FormContainer,
  FormHeader,
} from "../../atoms/Form/FormContainer.style";
import { SendButton } from "../../atoms/Buttons/Button.styles";
import { Grid, Box, Typography, Snackbar, Hidden } from "@material-ui/core";
import axios from "axios";
import { magClient } from "../../../mag-client";

const NOT_FOUND_STATUS_CODE = 404;
const BAD_REQUEST_STATUS_CODE = 404;
export interface magRequest {
  tool_id?: string;
  team_name: string;
  form_id: string;
  description: any;
}

const fetchTeams = async () => {
  return magClient.instance
    .get(`${process.env.REACT_APP_API_BASE_URL}/teams/names`)
    .then(async (response) => {
      if (response.data) {
        return response.data;
      }
      return Promise.reject({
        status:
          response.status === NOT_FOUND_STATUS_CODE
            ? NOT_FOUND_STATUS_CODE
            : BAD_REQUEST_STATUS_CODE,
        message: response,
      });
    });
};

const useTeams = () => {
  const [teams, setTeams] = useState(null);
  const [notFound, setNotFound] = useState(false);
  useEffect(() => {
    fetchTeams()
      .then((teams) => {
        const sortedTeams = teams.sort((a, b) =>
          a.team_name.toLowerCase() > b.team_name.toLowerCase() ? 1 : -1
        );
        setTeams(sortedTeams);
      })
      .catch((error) => {
        if (error.status === NOT_FOUND_STATUS_CODE) {
          setNotFound(true);
        }
        // eslint-disable-next-line no-console
        console.log({ error });
      });
  }, []);
  return { teams, notFound };
};

const fetchFormConfig = async (toolId: string, formId: string) => {
  return magClient.instance
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/tools/${toolId}/forms/${formId}`
    )
    .then(async (response) => {
      if (response.data) {
        return response.data;
      }
      return Promise.reject({
        status:
          response.status === NOT_FOUND_STATUS_CODE
            ? NOT_FOUND_STATUS_CODE
            : BAD_REQUEST_STATUS_CODE,
        message: response,
      });
    });
};

const useFormConfig = (toolId: string, formId: string) => {
  const [config, setConfig] = useState(null);
  const [notFound, setNotFound] = useState(false);
  useEffect(() => {
    fetchFormConfig(toolId, formId)
      .then((formConfig) => {
        setConfig({
          uiSchema: extractUiSchema(formConfig?.form_data?.properties),
          formSchema: extractFormJsonSchema(formConfig?.form_data),
          additionalData: {
            id: formConfig?.id,
            is_subscription_form: formConfig?.is_subscription_form,
            tool_id: formConfig?.tool_id,
            title: formConfig?.title,
            description: formConfig?.description,
          },
        });
      })
      .catch((error) => {
        if (error.status === NOT_FOUND_STATUS_CODE) {
          setNotFound(true);
        }
        // eslint-disable-next-line no-console
        console.log({ error });
      });
  }, [formId, toolId]);
  return { config, notFound };
};

export const FormPage: React.FC = () => {
  const FormGenerator = withTheme({
    ...MaterialUITheme,
    widgets: {
      TextWidget: FormInput,
      TextareaWidget: FormTextArea,
      RadioWidget: FormRadioButton,
      EmailWidget: FormEmailInput,
      CheckboxesWidget: FormCheckboxes,
      CheckboxWidget: FormCheckbox,
    },
  });
  const [team, setTeam] = useState("");
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const { id: toolId, formId } = useParams<{ id: string; formId: string }>();
  const { config: formConfig, notFound } = useFormConfig(toolId, formId);
  const { teams } = useTeams();
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const handleClose = () => {
    setOpenConfirmation(false);
  };
  const submitAllData = (data: any) => {
    setDisabledSubmitButton(true);
    const request: magRequest = {
      team_name: team,
      form_id: formId,
      tool_id: toolId,
      description: data,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    magClient.instance.post(`/tools/${toolId}/requests`, request).then(
      () => {
        setOpenConfirmation(true);
        setDisabledSubmitButton(false);
        window.scrollTo(0, 0);
        setTimeout(() => {
          history.back();
        }, 1000);
      },
      (reason) => {
        // eslint-disable-next-line no-console
        console.log("reason", reason);
      }
    );
  };

  if (notFound) {
    return (
      <Redirect
        to={{
          pathname: "/notFound",
        }}
      />
    );
  }
  if (!formConfig) return null;

  return (
    <LayoutPage>
      <Box my={2}>
        <BackNav />
      </Box>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} md={8}>
          <FormHeader>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              direction="row"
            >
              <Hidden smDown>
                <Grid item xs={12} md={4}>
                  <img
                    src="/assets/logo.png"
                    alt="tool"
                    style={{ maxWidth: "80%" }}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={8}>
                <Hidden smDown>
                  <Typography variant="h3" component="h2">
                    <Box fontWeight="fontWeightBold" my={1}>
                      {formConfig?.additionalData.title}
                    </Box>
                  </Typography>
                  <div>{formConfig.additionalData.description}</div>
                </Hidden>
                <Hidden smUp>
                  <Typography variant="h5" component="h2">
                    <Box fontWeight="fontWeightBold" my={1}>
                      {formConfig.additionalData.title}
                    </Box>
                  </Typography>
                  <div>{formConfig.additionalData.description}</div>
                </Hidden>
              </Grid>
            </Grid>
          </FormHeader>
        </Grid>
        <Grid item xs={12} md={8}>
          <Snackbar
            open={openConfirmation}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              elevation={6}
            >
              Your request has been sent !
            </Alert>
          </Snackbar>
          <FormContainer>
            <FormSelect
              teams={teams}
              required={true}
              callback={(value: string) => {
                setTeam(value);
                if (value === "" || value == "null") {
                  setDisabledSubmitButton(true);
                } else {
                  setDisabledSubmitButton(false);
                }
              }}
            />
            <FormGenerator
              schema={formConfig.formSchema}
              uiSchema={formConfig.uiSchema}
              onSubmit={(data) => {
                submitAllData(data.formData);
              }}
              onError={(error) => {
                window.scrollTo(0, 0);
              }}
              noHtml5Validate
              showErrorList
            >
              <Box width={"300"} fontWeight="fontWeightBold">
                <SendButton
                  disabled={disabledSubmitButton}
                  variant="contained"
                  color="primary"
                  type={"submit"}
                  size={"large"}
                >
                  Send
                </SendButton>
              </Box>
            </FormGenerator>
          </FormContainer>
        </Grid>
      </Grid>
    </LayoutPage>
  );
};
export default FormPage;
