import React from "react";
import { Divider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@material-ui/lab";
import * as Styled from "./AllTools.styles";
import Grid from "@material-ui/core/Grid";
import ToolItemSkeleton from "../../atoms/ToolItem/ToolItemSkeleton.component";

const AllToolsSkeleton = () => {
  return (
    <>
      <Typography variant="h5" component="h2">
        <Box fontWeight="fontWeightBold" my={1}>
          <Skeleton animation="wave" variant="text" />
        </Box>
      </Typography>
      <Divider />
      <Box my={2}>
        <Skeleton animation="wave" variant="rect" width={"100%"} height={40} />
      </Box>
      <Box my={3}>
        <Styled.Grid container alignItems="center" spacing={5}>
          {Array(12)
            .fill(null)
            .map((item, index) => (
              <Grid item xs={6} md={3} lg={3} key={index}>
                <ToolItemSkeleton />
              </Grid>
            ))}
        </Styled.Grid>
      </Box>
    </>
  );
};

export default AllToolsSkeleton;
