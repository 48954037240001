import React, { useEffect, useState } from "react";
import { Tool } from "../Types/tools.types";
import LayoutPage from "../../molecules/LayoutPage/Layout.page";
import AllTools from "../../molecules/AllTools/AllTools.component";
import AllSpotlights from "../../molecules/AllSpotlights/AllSpotlights.component";
import DashBoardSkeleton from "./DashboardSkeleton.component";
import { FiltersContext } from "../Contexts/FiltersContext.component";
import axios from "axios";
import { magClient } from "../../../mag-client";
import { useMsal } from "@azure/msal-react";
import * as Msal from "@azure/msal-browser";
import { msalConfig } from "../../../authConfig";
import { TokenStorage } from "../../../token-storage";

export const DashBoard: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [status, setStatus] = useState("idle");
  const [tools, setTools] = useState<null | Tool[]>(null);
  const [filteredTools, setFilteredTools] = useState(null);

  useEffect(() => {
    setStatus("loading");
    setIsLoading(true);
    magClient.instance
      .get(`/tools`)
      .then((res) => res.data)
      .then((result) => {
        setStatus("success");
        setTools(result);
        setFilteredTools(result);
        setIsLoading(false);
        return result;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setStatus("error");
      });
  }, []);

  const spotlights = tools?.filter((tool) => tool.spotlight);

  return (
    <LayoutPage>
      {isLoading ? (
        <DashBoardSkeleton />
      ) : (
        <div>
          {spotlights && <AllSpotlights spotlightTools={spotlights} />}
          <FiltersContext.Provider value={{ setFilteredTools }}>
            {tools && <AllTools tools={tools} filteredTools={filteredTools} />}
          </FiltersContext.Provider>
        </div>
      )}
    </LayoutPage>
  );
};
export default DashBoard;
