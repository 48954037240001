import styled from "styled-components";
import { default as MuiGrid } from "@material-ui/core/Grid";
import { default as MuiButton } from "@material-ui/core/Button";

export const Grid = styled(MuiGrid)`
  justify-content: center;
  ${(props: any) => props.theme.breakpoints.up("md")} {
    justify-content: flex-start;
  }
  > .MuiGrid-item {
    ${(props: any) => props.theme.breakpoints.down("xs")} {
      padding: 5px;
    }
  }
`;
export const Filter = styled(MuiButton)`
  :focus {
    color: #3f51b5;
    border-color: #3f51b5;
  }
`;
