import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

type InputProps = {
  value: string;
  label: string;
  required: boolean;
  multiline?: boolean;
  rows?: number;
  onChange: (value: string) => void;
};
export const FormInput: React.FC<InputProps> = ({
  value,
  required,
  onChange,
  label,
  multiline = false,
  rows = 2,
}) => {
  return (
    <FormControl>
      <TextField
        variant="filled"
        value={value ?? ""}
        rows={rows}
        multiline={multiline}
        required={required}
        onChange={({ target }) => onChange(target.value)}
        label={label}
        color="primary"
      />
    </FormControl>
  );
};
export default FormInput;
