import React from "react";
import Box from "@material-ui/core/Box";
import BackNav from "../../atoms/BackNav/BackNav.component";
import { Divider, Grid, Hidden, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
export const TipsGridSkeleton: React.FC = () => {
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} lg={12}>
        <Box my={2}>
          <BackNav />
        </Box>
        <Typography variant="h5" component="h2">
          <Skeleton animation="wave" variant="text" />
        </Typography>
        <Divider />
        <br />
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={12} md={8}>
            {Array(6)
              .fill(null)
              .map((item, index) => (
                <Box mb={3} mt={1} key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={"100%"}
                    height={170}
                  />
                </Box>
              ))}
          </Grid>
          <Hidden smDown>
            <Grid item xs={4}>
              <Grid container justify="flex-start" spacing={1}>
                <Grid item xs={12}>
                  <Box mt={1}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width={"100%"}
                      height={150}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TipsGridSkeleton;
