import React from "react";
import { SpotlightItem } from "../../organismes/Types/tools.types";
import { Paper } from "./Spotlight.styles";
import { Grid, Box } from "@material-ui/core";

export const Spotlight: React.FC<SpotlightItem> = ({
  imagePath,
  title,
  link,
}: SpotlightItem) => {
  return (
    <Box>
      <Paper elevation={0}>
        <a href={link}>
          <Grid container justify="center">
            <img
              src={
                imagePath ||
                `https://dummyimage.com/350x175/fff/000000&text=${title} logo`
              }
              alt="logo"
            />
          </Grid>
        </a>
      </Paper>
    </Box>
  );
};
export default Spotlight;
