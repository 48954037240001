import React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

type Options = {
  label: string | number;
  value: string;
};

type RadioProps = {
  value: string;
  label: string;
  required: boolean;
  onChange: (value: string) => void;
  options: {
    [key: string]: Options[] | any;
  };
  otherProps: Record<string, string | number>;
};
export const FormCheckbox: React.FC<any> = ({
  value,
  label,
  required,
  onChange,
  options,
  ...otherProps
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };
  return (
    <FormControl component="fieldset" required={required}>
      <FormGroup aria-label="checkbox" row={false}>
        <FormControlLabel
          control={<Checkbox color={"primary"} />}
          onChange={handleChange}
          label={otherProps?.schema?.title}
        />
      </FormGroup>
    </FormControl>
  );
};
export default FormCheckbox;
