import styled from "styled-components";
import { default as MuiGrid } from "@material-ui/core/Grid";
import { default as MuiPaper } from "@material-ui/core/Paper";
import { default as MuiTypography } from "@material-ui/core/Typography";

export const Typography = styled(MuiTypography)`
  ${(props: any) => props.theme.breakpoints.up("xs")} {
    font-size: 18px;
  }
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    font-size: 22px;
  }
  ${(props: any) => props.theme.breakpoints.up("md")} {
    font-size: 30px;
  }
  ${(props: any) => props.theme.breakpoints.up("lg")} {
    font-size: 30px;
  }
`;

export const Paper = styled(MuiPaper)`
  ${(props: any) => props.theme.breakpoints.up("xs")} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
    justify-content: stretch;
    height: 100%;
  }
  ${(props: any) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: stretch;
    text-align: left;
  }
  ${(props: any) => props.theme.breakpoints.up("lg")} {
    flex-direction: row;
    justify-content: stretch;
  }
  > .MuiGrid-item {
    ${(props: any) => props.theme.breakpoints.down("xs")} {
      padding: 5px;
    }
  }
`;

export const Grid = styled(MuiGrid)`
  ${(props: any) => props.theme.breakpoints.up("xs")} {
    flex-direction: column-reverse;
  }
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    flex-direction: column-reverse;
  }
  ${(props: any) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
  ${(props: any) => props.theme.breakpoints.up("lg")} {
    flex-direction: row;
  }
  > .MuiGrid-item {
    ${(props: any) => props.theme.breakpoints.down("xs")} {
      padding: 5px;
    }
  }
`;

export const GridTag = styled(MuiGrid)`
  ${(props: any) => props.theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`;
