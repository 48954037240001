import React from "react";
import AllSpotlightsSkeleton from "../../molecules/AllSpotlights/AllSpotlightsSkeleton.component";
import AllToolsSkeleton from "../../molecules/AllTools/AllToolsSkeleton.component";

export const DashBoardSkeleton: React.FC = () => {
  return (
    <>
      <AllSpotlightsSkeleton />
      <AllToolsSkeleton />
    </>
  );
};
export default DashBoardSkeleton;
