import styled from "styled-components";
import { default as MuiButton } from "@material-ui/core/Button";

export const Button = styled(MuiButton)`
  height: 48px;
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
  > span {
    padding: 0 30px;
    font-weight: bold;
    color: white;
  }
`;

export const SendButton = styled(MuiButton)`
  margin-top: ${(props) => props.theme.spacing(1) + "px"};
`;
