import React from "react";
import { LogoFormatedStyle } from "./LogoMag.style";

export const LogoMag: React.FC<{ shape?: "circle" | "rectangle" }> = ({
  shape = "rectangle",
}) => {
  return (
    <LogoFormatedStyle href={"/"}>
      <img src={`/assets/logo-mag-${shape}.png`} alt="" />
    </LogoFormatedStyle>
  );
};
export default LogoMag;
