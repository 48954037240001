import React from "react";
import { Button, Grid } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

export const BackNav: React.FC = () => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Button
        onClick={() => {
          history.back();
        }}
      >
        <ArrowBackIosRoundedIcon />
        Back
      </Button>
    </Grid>
  );
};
export default BackNav;
