import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
type TeamName = {
  team_name: string;
};

export const FormSelect: React.FC<{
  teams: TeamName[];
  required?: boolean;
  callback: (string) => void;
}> = ({ teams, callback, required }) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
        width: 100,
      },
    })
  );

  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    newValue: TeamName | null
  ) => {
    callback(newValue?.team_name || "");
    setValue(newValue?.team_name || "");
  };

  return (
    <>
      <Autocomplete
        id="demo-simple-select-outlined"
        className={classes.formControl}
        style={{ width: "100%" }}
        onChange={handleChange}
        options={teams ?? []}
        getOptionLabel={(option) => option.team_name || ""}
        getOptionSelected={(option, value) =>
          option.team_name === value.team_name
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={required}
              label="Danone Team Name"
            />
          );
        }}
      />
      <Box my={1}>
        <span style={{ fontSize: "0.8em", marginRight: "5px" }}>
          <em>*Cannot find your team ?</em>
        </span>
        <Button
          style={{
            fontSize: "0.7em",
            width: "150px",
            height: "15px",
          }}
          variant="outlined"
          color="primary"
          size="small"
          href="/TeamCreationForm"
        >
          Create a new team
        </Button>
      </Box>
    </>
  );
};
export default FormSelect;
