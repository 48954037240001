import axios, { AxiosInstance } from "axios";
import { TokenStorage } from "./token-storage";
import * as Msal from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

class MagClient {
  instance: AxiosInstance;
  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL: baseURL,
    });
    this.addJWTInterceptor();
    this.addCheckJWTOnUnauthorizedErrorInterceptor();
  }

  static build(baseURL: string) {
    return new MagClient(baseURL);
  }

  addJWTInterceptor() {
    this.instance.interceptors.request.use(
      (config) => {
        config.headers = {
          Authorization: "Bearer " + TokenStorage.getToken(),
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  addCheckJWTOnUnauthorizedErrorInterceptor() {
    this.instance.interceptors.response.use(
      (response) => {
        // eslint-disable-next-line no-console
        return response;
      },
      (error) => {
        if (error.response.status != 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        } else {
          return new Promise((resolve, reject) => {
            TokenStorage.getNewToken()
              .then((accessToken) => {
                TokenStorage.storeToken(accessToken);
                error.config.headers = {
                  Authorization: "Bearer " + TokenStorage.getToken(),
                };
                axios
                  .request(error.config)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                const app = new Msal.PublicClientApplication(msalConfig);
                app.logout();
              });
          });
        }
      }
    );
  }
}

export const magClient = MagClient.build(process.env.REACT_APP_API_BASE_URL);
