import React, { PropsWithChildren } from "react";
import Header from "../Header/Header.component";
import Nav from "../Nav/Nav.components";
import { Box } from "@material-ui/core";
import { Container, Main } from "./layout.style";

export const LayoutPage: React.FC = (props: PropsWithChildren<any>) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Container>
      <Header onClick={handleDrawerToggle} />
      <Nav mobileOpen={mobileOpen} onClick={handleDrawerToggle} />
      <Box pt={9}>
        <Main {...props} />
      </Box>
    </Container>
  );
};
export default LayoutPage;
