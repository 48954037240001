import React, { useEffect } from "react";
import { HomeLayout } from "./Home.styles";
import MuiButton from "../../atoms/Buttons/Button.component";
import RoundLogo from "../../atoms/LogoHome/LogoHome.component";
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../../authConfig";
import * as Msal from "@azure/msal-browser";
import { TokenStorage } from "../../../token-storage";

export const Home: React.FC = () => {
  const { instance } = useMsal();
  const accessTokenRequest = {
    scopes: ["user.read", "user.write"],
  };

  TokenStorage.acquireJWT(instance);

  const handleLoginClick = async () => {
    await instance.loginRedirect(loginRequest);
  };

  return (
    <HomeLayout>
      <RoundLogo />
      <h1>MAG portal - tools & tips for Danone Tech teams</h1>
      <MuiButton onClick={handleLoginClick} />
    </HomeLayout>
  );
};
export default Home;
