import React from "react";
import { Tips } from "../../organismes/Types/Tips.Types";
import { Tags } from "../Tags/Tags.component";
import { Box, Grid, Typography } from "@material-ui/core";
import * as Styled from "./TipItem.styles";

export const TipItem: React.FC<{ tips: Tips }> = ({ tips }) => {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={11} sm={12}>
        <Box mb={3}>
          <Styled.Paper>
            <Styled.Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={9}>
                <Box
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="column"
                  p={2}
                >
                  <Styled.Typography>{tips.title}</Styled.Typography>
                  <Tags tags={tips.tags} />
                  <Typography variant="subtitle2" component="p">
                    <Box my={2}>{tips?.summary.substring(0, 200)}</Box>
                  </Typography>
                  <Box>
                    <Typography variant="subtitle2" component="p">
                      {tips?.publication_date}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {tips.images_urls && (
                    <img
                      src={tips?.images_urls[0]}
                      style={{ width: "100px" }}
                      alt=""
                    />
                  )}
                </Box>
              </Grid>
            </Styled.Grid>
          </Styled.Paper>
        </Box>
      </Grid>
    </Grid>
  );
};
export default TipItem;
