import React from "react";
import { Icons, Tool } from "../../organismes/Types/tools.types";
import PopOver from "../PopOver/PopOver.component";
import { Box, Grid, Typography } from "@material-ui/core";
import { Paper } from "./ToolItem.styles";

export const ToolItem: React.FC<{ tool: Tool }> = ({ tool }) => {
  return (
    <Paper style={{ position: "relative" }}>
      <img
        src={tool.logo_icon_url}
        alt=""
        style={{
          maxWidth: "90%",
        }}
      />
      <Typography variant="h6" component="h3" align={"center"}>
        <Box fontWeight="fontWeightBold" marginY={1}>
          {tool.tool_name}
        </Box>
      </Typography>
      <Typography variant="subtitle2" component="p" align={"center"}>
        <Box>{tool.short_description}</Box>
      </Typography>
      <br />
      <Box style={{ position: "absolute", bottom: "10px" }}>
        <Typography variant="subtitle2" component="p" align={"center"}>
          <Box fontWeight="fontWeightBold">{`${tool.price}€ ${tool?.price_label}`}</Box>
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center">
          {tool?.status?.map((Icon: Icons, index) => (
            <PopOver
              key={index}
              text={Icon?.icon_id}
              textToPopOver={Icon?.icon_description}
            />
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};
export default ToolItem;
