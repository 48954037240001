import styled from "styled-components";
import { default as MuiPaper } from "@material-ui/core/Paper";
export const Paper = styled(MuiPaper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  height: 270px;
  padding: 30px 10px;
  > img {
    object-fit: contain;
    max-width: 100px;
    height: 90px;
  }
  > * {
    font-family: "Lato", sans-serif;
  }
`;
