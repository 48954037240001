import styled from "styled-components";

export const LogoFormatedStyle = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    height: 50px;
  }
`;
