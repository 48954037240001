import { Box, Popover, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

type PopOverProps = {
  text: string;
  textToPopOver: string;
};

export const PopOver: React.FC<PopOverProps> = ({
  text,
  textToPopOver,
}: PopOverProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      popover: {
        pointerEvents: "none",
      },
      paper: {
        padding: theme.spacing(1),
        scroll: "none",
      },
    })
  );

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box m={0.3}>
        <Typography
          variant="subtitle1"
          component="p"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {text}
        </Typography>
      </Box>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {textToPopOver}
      </Popover>
    </>
  );
};
export default PopOver;
