import React from "react";
import { Box, FormControlLabel, Radio } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

type Options = {
  label: string | number;
  value: string;
};

type RadioProps = {
  value: string;
  label: string;
  required: boolean;
  onChange: (value: string) => void;
  options: {
    [key: string]: Options[] | any;
  };
};
export const FormRadioButton: React.FC<RadioProps> = ({
  value,
  label,
  required,
  onChange,
  options,
}) => {
  return (
    <FormControl component="fieldset" required={required}>
      <Box component="span" mb={2}>
        <FormLabel component="legend">{label}</FormLabel>
      </Box>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={!value ? "" : value}
        onChange={({ target }) => onChange(target.value)}
      >
        {options?.enumOptions?.map(
          ({ label, value }: Options, index: React.Key | null | undefined) => (
            <FormControlLabel
              value={value.toString()}
              control={<Radio color="primary" />}
              label={label}
              labelPlacement="end"
              key={index}
            />
          )
        )}
      </RadioGroup>
    </FormControl>
  );
};
export default FormRadioButton;
