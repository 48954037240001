import styled from "styled-components";

export const RoundLogoStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: solid 1px lightgrey;
  overflow: hidden;
  box-shadow: 0 10px 6px -6px #777;
  img {
    position: absolute;
    width: 100%;
  }
`;
