import React from "react";
import { Box, Chip } from "@material-ui/core";
import * as Styled from "../TipItem/TipItem.styles";

export const Tags: React.FC<{ tags: string[] }> = ({ tags }) => {
  return (
    <Styled.GridTag
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      {tags.map((tag, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Chip
            color="primary"
            variant="outlined"
            size="small"
            label={tag}
            key={tag}
          />
        </Box>
      ))}
    </Styled.GridTag>
  );
};
