import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "./components/Variables.css";

import { ThemeProvider as SCThemeProvider } from "styled-components";
import { StylesProvider, useTheme } from "@material-ui/core/styles";

import DashBoard from "./components/organismes/Dashboard/Dashboard.page";
import Home from "./components/organismes/Home/Home.page";
import ToolDetail from "./components/organismes/ToolDetail/ToolDetail.page";
import NotFound from "./components/organismes/NotFound/NotFound.page";
import FormPage from "./components/organismes/Forms/Form.page";
import TipDetail from "./components/organismes/TipDetail/TipDetail.page";
import TeamCreationForm from "./components/organismes/TeamCreationForm/TeamCreationForm.page";
import TipsGrid from "./components/organismes/Tips/Tips.page";

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import Jwt from "./components/organismes/Jwt/Jwt.page";
import ReactGA from "react-ga4";
import RouteChangeTracker from "./components/atoms/RouterTrackerGoogleAnalytics/RouterTrackerGoogleAnalytics.component";

function App() {
  const muiTheme = useTheme();
  const msalInstance = new PublicClientApplication(msalConfig);

  const TRACKING_ID = "G-FF385KLNDR";
  ReactGA.initialize(TRACKING_ID);

  return (
    <StylesProvider injectFirst>
      <SCThemeProvider theme={muiTheme}>
        <MsalProvider instance={msalInstance}>
          <div className="App">
            <UnauthenticatedTemplate>
              <Home />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <BrowserRouter>
                <RouteChangeTracker />
                <Switch>
                  <Route exact path="/" component={DashBoard} />
                  <Route path="/tools/:id/forms/:formId" component={FormPage} />
                  <Route
                    path="/TeamCreationForm"
                    component={TeamCreationForm}
                  />
                  <Route exact path="/tips" component={TipsGrid} />
                  <Route path="/tips/:id" component={TipDetail} />
                  <Route path="/tools/:id" component={ToolDetail} />
                  <Route path="/jwt" component={Jwt} />
                  <Route component={NotFound} />
                </Switch>
              </BrowserRouter>
            </AuthenticatedTemplate>
          </div>
        </MsalProvider>
      </SCThemeProvider>
    </StylesProvider>
  );
}

export default App;
