import React from "react";
import { SpotlightsProps } from "../../organismes/Types/tools.types";
import NewToolItem from "../../atoms/Spotlight/Spotlight.component";
import { Divider, Typography, Grid, Box } from "@material-ui/core";

export const AllSpotlights: React.FC<{
  spotlightTools: SpotlightsProps[];
}> = ({ spotlightTools }) => {
  return (
    <>
      <Typography variant="h5" component="h2">
        <Box fontWeight="fontWeightBold" my={1}>
          New tools
        </Box>
      </Typography>
      <Divider />
      <Box marginY={2}>
        <Grid container spacing={5}>
          {spotlightTools.map((tool) => (
            <Grid item xs={12} md={6} lg={4} key={tool.id}>
              <NewToolItem
                imagePath={tool.logo_rectangle_url}
                title={tool.tool_name}
                description={tool.short_description}
                link={`/tools/${tool.tool_id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default AllSpotlights;
