import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const FormContainer = styled(Paper)`
  padding: 20px 40px;
`;

export const FormHeader = styled.div`
  background: #423e3f;
  padding: 20px;
  color: white;
`;
