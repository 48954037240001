import React from "react";
import { Skeleton } from "@material-ui/lab";

const ToolItemSkeleton = () => {
  return (
    <>
      <Skeleton animation="wave" variant="rect" width={"100%"} height={300} />
    </>
  );
};

export default ToolItemSkeleton;
