import axios, { AxiosRequestConfig } from "axios";
import * as Msal from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { magClient } from "./mag-client";

export class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = "token";
  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token";
  private static readonly LOCAL_STORAGE_TOKEN_TTL = "token-ttl";

  static instance = new TokenStorage();

  private static readonly accessTokenRequest = {
    scopes: ["user.read", "user.write"],
  };

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public static acquireJWT(msal: IPublicClientApplication) {
    const app = new Msal.PublicClientApplication(msalConfig);
    msal
      .acquireTokenSilent({
        account: app.getAllAccounts()[0],
        scopes: ["user.read"],
      })
      .then(function (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken;
        TokenStorage.storeToken(accessTokenResponse.idToken);
        TokenStorage.storeTokenTTL(
          accessTokenResponse.expiresOn.toDateString() +
            "-" +
            accessTokenResponse.expiresOn.getHours() +
            "-" +
            accessTokenResponse.expiresOn.getMinutes()
        );
      })
      .catch(function (err) {
        if (err.name === "InteractionRequiredAuthError") {
          return msal
            .acquireTokenPopup(TokenStorage.accessTokenRequest)
            .then((accessTokenResponse) => {
              TokenStorage.storeToken(accessTokenResponse.idToken);
              TokenStorage.storeTokenTTL(
                accessTokenResponse.expiresOn.toDateString() +
                  "-" +
                  accessTokenResponse.expiresOn.getHours() +
                  "-" +
                  accessTokenResponse.expiresOn.getMinutes()
              );
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
              msal.logout();
            });
        }
      });
  }

  public static storeTokenTTL(ttl: string) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN_TTL, ttl);
  }

  public static async getNewToken(): Promise<string> {
    const app = new Msal.PublicClientApplication(msalConfig);
    try {
      const accessTokenResponse = await app.acquireTokenSilent({
        account: app.getAllAccounts()[0],
        scopes: ["user.read"],
      });
      return Promise.resolve(accessTokenResponse.idToken);
    } catch (err) {
      if (err.name === "InteractionRequiredAuthError") {
        const accessToken = await app.acquireTokenPopup(
          TokenStorage.accessTokenRequest
        );
        return Promise.resolve(accessToken.idToken);
      }
      throw "failed to refresh json web token";
    }
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  private static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }
}
