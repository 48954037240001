import React from "react";
import { HomeLayout } from "../Home/Home.styles";
import RoundLogo from "../../atoms/LogoHome/LogoHome.component";
import { Typography } from "@material-ui/core";

export const NotFound: React.FC = () => {
  return (
    <>
      <HomeLayout>
        <Typography variant="h3" component="div">
          Page Not Found
        </Typography>
        <RoundLogo />
      </HomeLayout>
    </>
  );
};
export default NotFound;
