import React, { useReducer, useState, useEffect } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import LayoutPage from "../../molecules/LayoutPage/Layout.page";
import BackNav from "../../atoms/BackNav/BackNav.component";
import TeamMapper from "./TeamMapper";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import { SendButton } from "../../atoms/Buttons/Button.styles";
import { FormHeader } from "../../atoms/Form/FormContainer.style";
import {
  Box,
  createStyles,
  Divider,
  FormControl,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { magClient } from "../../../mag-client";

type MagPortalResponseStatus = {
  message: string;
  severity: AlertProps["color"];
};

export const TeamCreationForm: React.FC = () => {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [ruCodeValue, setRuCodeValue] = useState<string>("");
  const [teamEmailError, setTeamEmailError] = useState<string | null>(null);
  const [billingOwnerEmailError, setBillingOwnerEmailError] = useState<
    string | null
  >(null);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const [
    magResponseStatus,
    setMagResponseStatus,
  ] = useState<MagPortalResponseStatus>({ message: "", severity: "success" });
  const handleClose = () => {
    setOpenConfirmation(false);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          margin: theme.spacing(2),
        },
      },
    })
  );
  const classes = useStyles();

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );

  const handleSubmit = (evt) => {
    setDisabledSubmitButton(true);
    evt.preventDefault();
    const dataToSend = TeamMapper.toDTO(formInput);
    magClient.instance
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/teams`,
        JSON.stringify(dataToSend)
      )
      .then((response) => {
        if (response.status == 201) {
          setMagResponseStatus({
            message: "Team has been created",
            severity: "success",
          });
          setOpenConfirmation(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.back();
          }, 1000);
        } else {
          let magStatusReponse: MagPortalResponseStatus;
          if (response.status == 409) {
            magStatusReponse = {
              message: "This team already exists",
              severity: "error",
            };
          } else {
            magStatusReponse = {
              message: "Bad request",
              severity: "error",
            };
          }
          setMagResponseStatus(magStatusReponse);
          setOpenConfirmation(true);
          setDisabledSubmitButton(false);
          window.scrollTo(0, 0);
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error:", error);
      });
  };

  const isEmail = (input: string): boolean => {
    // Simple email validation regex
    const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    return emailRegex.test(input);
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    switch (name) {
      case "email_team":
        if (!isEmail(newValue)) {
          setTeamEmailError("Invalid email address");
          setDisabledSubmitButton(true);
        } else {
          setTeamEmailError(null); // Clear error if valid
        }
        break;
      case "billing_owner":
        if (!isEmail(newValue)) {
          setBillingOwnerEmailError("Invalid email address");
          setDisabledSubmitButton(true);
        } else {
          setBillingOwnerEmailError(null); // Clear error if valid
        }
        break;
      case "ru_code":
        setRuCodeValue(newValue);
        break;
    }
    setFormInput({ [name]: newValue });
  };

  useEffect(() => {
    if (!teamEmailError && !billingOwnerEmailError) {
      setDisabledSubmitButton(false);
    } else {
      setDisabledSubmitButton(true);
    }
  }, [teamEmailError, billingOwnerEmailError]);

  return (
    <LayoutPage>
      <Box my={2}>
        <BackNav />
      </Box>
      <Snackbar
        open={openConfirmation}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={magResponseStatus.severity}
          variant="filled"
          elevation={6}
        >
          {magResponseStatus.message}
        </Alert>
      </Snackbar>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} md={8}>
          <FormHeader>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              direction="row"
            >
              <Hidden smDown>
                <Grid item xs={12} md={4}>
                  <img
                    src="/assets/logo.png"
                    alt="tool"
                    style={{ maxWidth: "80%" }}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={8}>
                <Hidden smDown>
                  <Typography variant="h3" component="h2">
                    <Box fontWeight="fontWeightBold" my={1}>
                      {"Team creation form"}
                    </Box>
                  </Typography>
                  <div>{"Fill the form to create a team creation request"}</div>
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="h5" component="h2">
                    <Box fontWeight="fontWeightBold" my={1}>
                      {"Team creation form"}
                    </Box>
                  </Typography>
                  <div>{"Fill the form to create a team creation request"}</div>
                </Hidden>
              </Grid>
            </Grid>
          </FormHeader>
          <Paper>
            <Box py={4}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={10} md={11}>
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      className={classes.root}
                      style={{ width: "100%" }}
                    >
                      <Box>
                        <Typography variant="h5" component="h2">
                          Team information
                        </Typography>
                        <Divider />
                      </Box>
                      <TextField
                        required
                        variant="filled"
                        label={"Team Name"}
                        name={"team_name"}
                        color="primary"
                        onChange={handleInput}
                      />
                      <TextField
                        variant="filled"
                        label={"Comments"}
                        name={"comments"}
                        color="primary"
                        onChange={handleInput}
                      />
                      <Box>
                        <Typography variant="h5" component="h2">
                          Team owner
                        </Typography>
                        <Divider />
                      </Box>
                      <TextField
                        required
                        variant="filled"
                        label={"Email"}
                        name={"email_team"}
                        color="primary"
                        onChange={handleInput}
                        inputProps={{
                          pattern:
                            "^[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$", // Regular expression for email address
                        }}
                        error={!!teamEmailError}
                        helperText={teamEmailError || ""}
                      />
                      <Box>
                        <Typography variant="h5" component="h2">
                          Billing information (see the RU CODE list:
                          <a
                            href="https://danone.sharepoint.com/:x:/s/FR-MAG_Modern_App_Garage/EcKU9PDFEuxPgxbVvet3tb0BAezsxps_jvridCiFJjXyfw"
                            target="_blank"
                            rel="noreferrer"
                          >
                            HERE
                          </a>
                          )
                        </Typography>
                        <Divider />
                      </Box>
                      <TextField
                        required
                        variant="filled"
                        label={"Geography"}
                        name={"geography"}
                        color="primary"
                        onChange={handleInput}
                      />
                      <TextField
                        variant="filled"
                        label={"Billing owner email"}
                        name={"billing_owner"}
                        color="primary"
                        onChange={handleInput}
                        inputProps={{
                          pattern:
                            "^[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$", // Regular expression for email address
                        }}
                        error={!!billingOwnerEmailError}
                        helperText={billingOwnerEmailError || ""}
                      />
                      <TextField
                        required
                        variant="filled"
                        label={"Reporting Unit code"}
                        name={"ru_code"}
                        color="primary"
                        onChange={handleInput}
                        inputProps={{
                          pattern: "^\\d{4}$", // Regular expression for exactly four digits
                        }}
                        helperText={"4 digits"}
                      />
                      <TextField
                        required={ruCodeValue === "0100"}
                        variant="filled"
                        label={"Cost Center code"}
                        name={"cc_code"}
                        color="primary"
                        onChange={handleInput}
                        inputProps={{
                          pattern: "^\\d{6}$", // Regular expression for exactly six digits
                        }}
                        helperText={"6 digits"}
                      />
                      <Box>
                        <SendButton
                          disabled={disabledSubmitButton}
                          variant="contained"
                          color="primary"
                          type={"submit"}
                          size={"large"}
                        >
                          Send
                        </SendButton>
                      </Box>
                    </FormControl>
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </LayoutPage>
  );
};
export default TeamCreationForm;
