import React from "react";
import { RoundLogoStyled } from "./LogoHome.styles";

export const RoundLogo: React.FC = () => {
  return (
    <RoundLogoStyled>
      <img src={"/assets/logo-mag-circle.png"} alt="logo" />
    </RoundLogoStyled>
  );
};
export default RoundLogo;
