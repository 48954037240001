import React from "react";
import { Skeleton } from "@material-ui/lab";

const SpotlightSkeleton = () => {
  return (
    <Skeleton animation="wave" variant="rect" width={"100%"} height={170} />
  );
};

export default SpotlightSkeleton;
