import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  > div {
    width: 100%;
  }
`;
export const Main = styled.main`
  background: center / cover fixed no-repeat url("/assets/background.png");
  flex-grow: 1;
  min-height: 100vh;
  padding-top: ${(props) => props.theme.spacing(1) + "px"};
  padding-bottom: ${(props) => props.theme.spacing(1) + "px"};
  padding-right: ${(props) => props.theme.spacing(3) + "px"};
  padding-left: ${(props) => props.theme.spacing(3) + "px"};
`;
