import React, { useEffect, useState } from "react";
import LayoutPage from "../../molecules/LayoutPage/Layout.page";
import TipItem from "../../atoms/TipItem/TipItem.component";
import { Tips } from "../Types/Tips.Types";
import { tipsRepo } from "../../../repository/tips-repository";
import TipsGridSkeleton from "./Tips.skeleton";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Hidden,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";

export const TipsGrid: React.FC = () => {
  const [allTips, setAllTips] = useState<null | Tips[]>(null);
  const [status, setStatus] = useState("idle");
  const [isLoading, setIsLoading] = React.useState(true);

  const getTags = (tips) => {
    const tagsMap = new Map<string, number>();
    tips?.forEach((tip) => {
      tip?.tags?.forEach((tag) => {
        tagsMap.set(tag, 1);
      });
    });
    return [...tagsMap.keys()];
  };
  const allTags = getTags(allTips);

  useEffect(() => {
    setStatus("loading");
    setIsLoading(true);

    tipsRepo
      .getAllTips()
      .then((result) => {
        setAllTips(result);
        setStatus("success");
        setIsLoading(false);
      })
      .catch((error) => {
        setStatus("error");
      });
  }, []);

  return (
    <LayoutPage>
      {isLoading ? (
        <TipsGridSkeleton />
      ) : (
        <Grid container justify="flex-start">
          <Grid item xs={12} lg={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight="fontWeightBold" my={1}>
                Tips
              </Box>
            </Typography>
            <Divider />
            <br />
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} md={8}>
                {allTips?.map((tips, index) => (
                  <Link
                    key={index}
                    href={`/tips/${tips.tips_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <TipItem tips={tips} />
                  </Link>
                ))}
              </Grid>
              <Hidden smDown>
                <Grid item xs={4}>
                  <Grid container justify="flex-start" spacing={1}>
                    <Grid item xs={12}>
                      <Paper elevation={3}>
                        <Box p={2}>
                          <Typography variant="h5" component="h2">
                            <Box fontWeight="fontWeightBold">Tags</Box>
                          </Typography>
                          <Divider />
                          <Box
                            display="flex"
                            alignItems="center"
                            py={2}
                            flexWrap={"wrap"}
                          >
                            {allTags.map((tag, index) => (
                              <Box m={1} key={index}>
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  label={tag}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      )}
    </LayoutPage>
  );
};
export default TipsGrid;
