import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import BallotIcon from "@material-ui/icons/Ballot";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  Divider,
  Hidden,
  IconButton,
  Link,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import * as Style from "./Nav.styles";

const Menu = ({ onDrawerToggle }: any) => {
  return (
    <div>
      <Style.DrawerHeader>
        <IconButton onClick={onDrawerToggle}>
          <ChevronLeftIcon />
        </IconButton>
      </Style.DrawerHeader>
      <Divider />
      <List>
        {[
          { name: "Tools", icon: <BallotIcon />, href: "/" },
          { name: "Tips", icon: <AssignmentIcon />, href: "/tips" },
        ].map(({ name, icon, href }) => (
          <Link color="inherit" href={href} key={name}>
            <Style.ListItem button key={name}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={name} />
            </Style.ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
};
type NavProps = {
  onClick: () => void;
  mobileOpen: boolean;
};
const Nav: React.FC<NavProps> = ({ onClick, mobileOpen }: NavProps) => {
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const iOS = navigator && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Style.NavContainer aria-label="menu">
      <Hidden smUp implementation="css">
        <Style.SwappableDrawer
          container={container}
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={onClick}
          onOpen={onClick}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Menu onDrawerToggle={onClick} />
        </Style.SwappableDrawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Style.Drawer variant="permanent" open>
          <Menu onDrawerToggle={onClick} />
        </Style.Drawer>
      </Hidden>
    </Style.NavContainer>
  );
};

export default Nav;
