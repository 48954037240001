import React from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

type Options = {
  label: string | number;
  value: string;
};

type RadioProps = {
  value: string;
  label: string;
  required: boolean;
  onChange: (value: string) => void;
  options: {
    [key: string]: Options[] | any;
  };
  otherProps: Record<string, string | number>;
};
export const FormCheckboxes: React.FC<any> = ({
  value,
  label,
  required,
  onChange,
  options,
  ...otherProps
}) => {
  return (
    <FormControl component="fieldset" required={required}>
      <Box component="span" my={2}>
        <FormLabel component="legend">
          <Box component={"span"} fontWeight="fontWeightBold">
            {otherProps?.schema?.title}
          </Box>
        </FormLabel>
      </Box>
      <FormGroup aria-label="checkbox" row={false}>
        {options?.enumOptions?.map(
          ({ label }: Options, index: React.Key | null | undefined) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={label.toString()}
                  onChange={() => onChange(value.concat(label.toString()))}
                  color={"primary"}
                />
              }
              label={label}
              key={index}
            />
          )
        )}
      </FormGroup>
    </FormControl>
  );
};
export default FormCheckboxes;
