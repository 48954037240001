import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

type InputProps = {
  value: string;
  name?: string;
  label: string;
  required: boolean;
  multiline?: boolean;
  rows?: number;
  pattern?: string;
  onChange: (value: string) => void;
};
export const FormEmailInput: React.FC<any> = ({
  value,
  name,
  required,
  onChange,
  label,
  multiline = false,
  rows = 2,
  ...otherProps
}) => {
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  const isEmail = (input: string): boolean => {
    // Simple email validation regex
    const emailRegex = otherProps.pattern
      ? new RegExp(otherProps.pattern)
      : new RegExp(otherProps.schema.pattern); // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(input);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // Validate email if the value is "email"
    if (!isEmail(value)) {
      setError("Invalid email address");
    } else {
      setError(null); // Clear error if valid
    }
    onChange(value);
  };

  return (
    <FormControl>
      <TextField
        name="emailInput"
        variant="filled"
        value={formData.emailInput ?? ""}
        rows={rows}
        multiline={multiline}
        required={required}
        onChange={(e) => {
          handleInputChange(e);
        }} //{(e) => handleInputChange(e)}
        //onChange={({ target }) =>
        //  setFormData({ ...formData, emailInput: target.value })
        //}
        label={label}
        color="primary"
        error={!!error}
        helperText={error || ""}
      />
    </FormControl>
  );
};
export default FormEmailInput;
