import React from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import LogoMag from "../../atoms/LogoMag/LogoMag.component";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Box, Typography } from "@material-ui/core";
import * as Styled from "./Header.styles";

type HeaderProps = {
  onClick: () => void;
};

export const Header: React.FC<HeaderProps> = ({ onClick }: HeaderProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  return (
    <Styled.AppBar color={"transparent"} position={"fixed"}>
      <Styled.Toolbar color="inherit">
        <Styled.IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onClick}
        >
          <Styled.MenuIcon />
        </Styled.IconButton>
        <LogoMag />
        <Box>
          <Typography variant="body1" component="span">
            {account.name}
          </Typography>
          <Styled.Button onClick={() => instance.logout()}>
            <PowerSettingsNewIcon color="action" />
          </Styled.Button>
        </Box>
      </Styled.Toolbar>
    </Styled.AppBar>
  );
};
export default Header;
