import React from "react";
import FormInput from "../FormInput/FormInput.component";
import FormEmailInput from "../FormInput/FormEmailInput.component";
type TextAreaProps = {
  value: string;
  label: string;
  required: boolean;
  otherProps: Record<string, string | number>;
  onChange: (value: string) => void;
};
export const FormTextArea: React.FC<any> = ({
  value,
  required,
  onChange,
  label,
  ...otherProps
}) => {
  if (otherProps?.schema?.pattern?.includes("@")) {
    //email address pattern required
    return (
      <FormEmailInput
        {...{
          value,
          required,
          onChange,
          label,
          rows: 4,
          multiline: true,
          pattern: otherProps?.schema?.pattern,
        }}
      />
    );
  } else {
    return (
      <FormInput
        {...{
          value,
          required,
          onChange,
          label,
          rows: 4,
          multiline: true,
        }}
      />
    );
  }
};

export default FormTextArea;
