import TeamDTO from "./TeamDTO";
type FormInput = {
  cc_code: string;
  comments: string;
  email_team: string;
  geography: string;
  role_team: string;
  ru_code: string;
  team_name: string;
  team_owner: string;
};
const TeamMapper = {
  toDTO: (formInput: FormInput): TeamDTO => {
    return {
      team_name: formInput.team_name,
      comments: formInput.comments,
      team_owner: [
        {
          email: formInput.email_team,
          role: "",
        },
      ],
      billing_information: {
        ru_code: formInput.ru_code,
        cc_code: formInput.cc_code,
      },
      geography: formInput.geography,
    };
  },
};
export default TeamMapper;
