import { JSONSchema7 } from "json-schema";

export const extractFormJsonSchema = (formConfigData: any): JSONSchema7 => {
  const properties = Object.keys(formConfigData.properties).reduce(
    (acc, current) => {
      const { "ui:widget": widget, ...others } = formConfigData.properties[
        current
      ];
      return {
        ...acc,
        [current]: others,
      };
    },
    {}
  );
  return {
    type: formConfigData.type,
    required: formConfigData.required,
    properties,
  };
};
const INPUT_WIDGET_NAME = "input";
export const extractUiSchema = (formProperties: any) => {
  return Object.keys(formProperties).reduce((acc, current) => {
    const widgetName = formProperties[current]["ui:widget"];
    if (widgetName === INPUT_WIDGET_NAME) return acc;
    return {
      ...acc,
      [current]: {
        "ui:widget": formProperties[current]["ui:widget"],
      },
    };
  }, {});
};
